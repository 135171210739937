@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+Thai:wght@100..900&display=swap");

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-width: 320px;
}

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

.gradient-text {
  /* color: #ff00ff;
  text-shadow: 0 0 10px #ff00ff;
  background: linear-gradient(0deg, #fff 10%, #ff69ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  letter-spacing: 1px; */
  background: linear-gradient(
    0deg,
    #fff 0%,
    #ffe6ff 40%,
    #ff80ff 75%,
    #ff66ff 78%,
    #ff4dff 81%,
    #ff33ff 84%,
    #ff1aff 90%,
    #ff69ff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes heroTop {
  0%,
  100% {
    top: 239px;
  }
  50% {
    top: 231px;
  }
}

@keyframes heroMid {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes heroBot {
  0%,
  100% {
    top: 4px;
  }
  50% {
    top: 8px;
  }
}
